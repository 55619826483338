
















































































import { Component, Vue } from 'vue-property-decorator';
import { IPontoAcessoCreateUpdate } from '@/interfaces/ponto_acesso';
import { readPontosAcesso } from '@/store/ponto_acesso/getters';
import { readLocalidades } from '@/store/localidade/getters';
import { readResponsaveisPorPontoAcesso } from '@/store/responsavel/getters';
import { dispatchCreatePontoAcesso, dispatchMensagemErroCodigoLocalidade } from '@/store/ponto_acesso/actions';
import { dispatchGetPontosAcesso } from '@/store/ponto_acesso/actions';
import { ILocalidadeSelectFilter } from '@/interfaces/localidade';
import { dispatchGetLocalidadesFiltradas } from '@/store/localidade/actions';
import { dispatchGetLocalidades } from '@/store/localidade/actions';
import cacheDominiosEstaticos from '@/store/ponto_acesso/cache';

@Component
export default class CadastrarPontoAcesso extends Vue {
  public valid = false;
  public idResponsavel: number = 0;
  public macAddress: string = '';
  public codigoLocalidade: string = '';
  public latitude: number = 0.0;
  public longitude: number = 0.0;

  public async mounted() {
    if (this.$router.currentRoute.params.codigo) {
      this.codigoLocalidade = this.$router.currentRoute.params.codigo;
    }
    if (this.$router.currentRoute.params.latitude) {
      this.latitude = parseFloat(this.$router.currentRoute.params.latitude);
    }
    if (this.$router.currentRoute.params.longitude) {
      this.longitude = parseFloat(this.$router.currentRoute.params.longitude);
    }
    await cacheDominiosEstaticos(this.$store);
    this.reset();
  }

  public reset() {
    this.idResponsavel = 0;
    this.macAddress = '';
    if (this.$router.currentRoute.params.latitude) {
      this.latitude = parseFloat(this.$router.currentRoute.params.latitude);
    }
    else{
      this.latitude = 0.0;
    }
    if (this.$router.currentRoute.params.longitude) {
      this.longitude = parseFloat(this.$router.currentRoute.params.longitude);
    }
    else{
      this.longitude = 0.0;
    }

    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public uppercase() {
    this.macAddress = this.macAddress.toUpperCase();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const filtros: ILocalidadeSelectFilter = {
        codigo_localidade: this.codigoLocalidade,
        origem: [],
        reponsavel: [],
        nome: '',
        referencia: '',
        status: '',
        tipo_publicidade: [],
        tipo_localidade: [],
        subprefeitura: [],
        distrito: [],
        bairro: [],
        zona: [],
        endereco: '',
        possui_pontos_acesso: '',
        ordenacao: false,
        sentido: '',
        campoOrdenar: '',
        skip: 0,
      };
      await dispatchGetLocalidadesFiltradas(this.$store, filtros);

      if (this.localidades[0]) {
        const novoPontoAcesso: IPontoAcessoCreateUpdate = {
          id_localidade: this.localidades[0].id,
          id_responsavel: this.idResponsavel,
          mac_address: this.macAddress,
          status: true,
          latitude: this.latitude,
          longitude: this.longitude,
        };
        const cadastrou = await dispatchCreatePontoAcesso(this.$store, novoPontoAcesso);
        if (cadastrou) {
          await dispatchGetPontosAcesso(this.$store, 0);
          await dispatchGetLocalidades(this.$store, 0);
          this.$router.push('/main/ponto_acesso/listar');
        }
      } else {
        dispatchMensagemErroCodigoLocalidade(this.$store);
      }
    }
  }

  get pontosAcesso() {
    return readPontosAcesso(this.$store);
  }

  get localidades() {
    return readLocalidades(this.$store);
  }

  get responsaveis() {
    return readResponsaveisPorPontoAcesso(this.$store);
  }
}
